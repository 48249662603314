import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { PROJECT_API } from "../../Api";

const ProjectApi = createApi({
  reducerPath: "Project",
  baseQuery,
  tagTypes: ["Project"],
  endpoints: (builder) => ({
    getProject: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: PROJECT_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PROJECT_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Project"],
    }),
    getProjectById: builder.query({
      query: (id) => {
        return {
          url: `${PROJECT_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Project"],
    }),
    addProject: builder.mutation({
      query: (payload) => ({
        url: PROJECT_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Project"],
    }),
    updateProject: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `${PROJECT_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Project"],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `${PROJECT_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
  }),
});

export const {
  useGetProjectQuery,
  useGetProjectByIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = ProjectApi;

export default ProjectApi;
