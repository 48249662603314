import { createSlice } from "@reduxjs/toolkit";

const initialState = { previewCostingData: null };

export const previewCosting = createSlice({
  name: "previewCosting",
  initialState,
  reducers: {
    previewCostingData: (state, action) => {
      state.previewCostingData = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { previewCostingDatas } = previewCosting.actions;

export default previewCosting.reducer;
