import React, { useState } from 'react'
import { BELL_ICON, CIRCLE_TICK_ICON, CLOSE_ICON, VIEW } from '../../../icons'
import useOutsideClick from '../../../CustomHooks/handleOutsideClick'
import { useGetNotificationQuery, useUpdateNotificationMutation } from '../../../redux/ErpServices/NotificationServices'
import { getCommonParams } from '../../../Utils/helper'
import useNotification from '../../../CustomHooks/useNotification'
import { toast } from 'react-toastify'
import { push } from '../../../redux/features/opentabs'
import { useDispatch } from 'react-redux'
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService'

const Notification = () => {
    const [notificationPanel, setNotificationPanel] = useState(false)

    const ref = useOutsideClick(() => {
        setNotificationPanel(false)
    })
    const { userId, branchId } = getCommonParams()
    const dispatch = useDispatch()

    const { data: userData } = useGetUserByIdQuery(userId)
    const { data, refetch } = useGetNotificationQuery({ params: { receiverId: userId, isAdmin: userData?.data?.isAdmin ? JSON.parse(userData?.data?.isAdmin) : false, branchId } });
    const [update] = useUpdateNotificationMutation()
    const notifications = data?.data || [];
    useNotification(() => {
        refetch();
        setNotificationPanel(true)
    })
    async function markAsRead(id) {
        const returnData = await update({ id, isRead: true }).unwrap();
        if (returnData?.statusCode === 0) {
            return toast.success(returnData.message)
        } else {
            return toast.error(returnData.message)
        }
    }
    function getStyleByType(type) {
        if (type === "Costing Approved") return 'text-green-600'
        if (type === "Costing Back To Review") return 'text-yellow-600'
        if (type === "Costing Reverted") return 'text-red-600'
        // if (type === "Costing Reverted") return 'text-red-600'
    }

    function onPreview(i) {
        if (userData?.data?.isAdmin) {
            dispatch(push({ name: "COSTING APPROVAL", previewId: i?.costingId }));
            dispatch({
                type: `Costing/invalidateTags`,
                payload: ['Costing'],
            });
        } else {
            dispatch(push({ name: "COSTING", previewId: i?.costingId }));
            dispatch({
                type: `Costing/invalidateTags`,
                payload: ['Costing'],
            });
        }
        markAsRead(i.id)
        setNotificationPanel(false)
    }

    return (
        <div className='relative' ref={ref} >
            <div
                className="text-lg"
                onClick={() => { setNotificationPanel(true) }}>
                <span>

                    {BELL_ICON}
                </span>
                {notifications.length !== 0 &&
                    <span className={`${false ? "hidden" : "absolute -top-[10px] left-[13px] bg-red-500 h-5 w-5 text-[15px] rounded-full flex items-center justify-center"}`}>
                        {notifications.length}
                    </span>
                }
            </div>
            {notificationPanel && (notifications.length > 0)
                &&
                <div className='absolute -left-[300px] top-[30px] z-50 text-xs bg-gray-200 p-1'>
                    <div className="relative overflow-x-auto">
                        <div className='flex justify-between items-center'>
                            <div></div>
                            <div className='text-lg text-blue-700 font-bold'>Notifications</div>
                            <button onClick={() => { setNotificationPanel(false) }} className='bg-red-500 p-1' >
                                {CLOSE_ICON}
                            </button>
                        </div>
                        <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-2 py-3">
                                        Type
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Style
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        By
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        View
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Mark as read
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifications.map(i =>
                                    <tr key={i.id} className="border-b dark:bg-gray-800 dark:border-gray-700 text-gray-700">
                                        <th scope="row" className={`px-2 py-4 font-bold whitespace-nowrap ${getStyleByType(i.type)}`}>
                                            {i?.type}
                                        </th>
                                        <th scope="row" className="px-2 py-4 font-medium whitespace-nowrap ">
                                            {i?.Costing?.styleRefNo}
                                        </th>
                                        <td className="px-2 py-4">
                                            {i?.Sender?.username}
                                        </td>
                                        <td className="px-2 py-4 flex items-center justify-center">
                                            <button onClick={() => {
                                                onPreview(i)
                                            }}>
                                                {VIEW}
                                            </button>
                                        </td>
                                        <td className="px-2 py-4 ">
                                            <div className='flex items-center justify-center'>
                                                <button className='text-blue-500' onClick={() => { markAsRead(i.id) }}>
                                                    {CIRCLE_TICK_ICON}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}

export default Notification
