import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { PROCESS_INWARD_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const ProcessInwardApi = createApi({
  reducerPath: "ProcessInward",
  baseQuery,
  tagTypes: ["ProcessInward"],
  endpoints: (builder) => ({
    getProcessInward: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: PROCESS_INWARD_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PROCESS_INWARD_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ProcessInward"],
    }),
    getProcessInwardById: builder.query({
      query: (id) => {
        return {
          url: `${PROCESS_INWARD_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ProcessInward"],
    }),
    addProcessInward: builder.mutation({
      query: (payload) => ({
        url: PROCESS_INWARD_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ProcessInward"],
    }),
    updateProcessInward: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `${PROCESS_INWARD_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ProcessInward"],
    }),
    deleteProcessInward: builder.mutation({
      query: (id) => ({
        url: `${PROCESS_INWARD_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProcessInward"],
    }),
  }),
});

export const {
  useGetProcessInwardQuery,
  useGetProcessInwardByIdQuery,
  useAddProcessInwardMutation,
  useUpdateProcessInwardMutation,
  useDeleteProcessInwardMutation,
} = ProcessInwardApi;

export default ProcessInwardApi;
