import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { STYLE_FABRIC_TEMPLATE } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const StyleFabricTemplateApi = createApi({
  reducerPath: "StyleFabricTemplate",
  baseQuery,
  tagTypes: ["StyleFabricTemplate"],
  endpoints: (builder) => ({
    getStyleFabricTemplate: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: STYLE_FABRIC_TEMPLATE + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: STYLE_FABRIC_TEMPLATE,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["StyleFabricTemplate"],
    }),
    getStyleFabricTemplateById: builder.query({
      query: (id) => {
        return {
          url: `${STYLE_FABRIC_TEMPLATE}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["StyleFabricTemplate"],
    }),
    addStyleFabricTemplate: builder.mutation({
      query: (payload) => ({
        url: STYLE_FABRIC_TEMPLATE,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["StyleFabricTemplate"],
    }),
    updateStyleFabricTemplate: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${STYLE_FABRIC_TEMPLATE}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["StyleFabricTemplate"],
    }),
    deleteStyleFabricTemplate: builder.mutation({
      query: (id) => ({
        url: `${STYLE_FABRIC_TEMPLATE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["StyleFabricTemplate"],
    }),
  }),
});

export const {
  useGetStyleFabricTemplateQuery,
  useGetStyleFabricTemplateByIdQuery,
  useAddStyleFabricTemplateMutation,
  useUpdateStyleFabricTemplateMutation,
  useDeleteStyleFabricTemplateMutation,
} = StyleFabricTemplateApi;

export default StyleFabricTemplateApi;
