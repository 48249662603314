import React from 'react'
import { DELETE, PLUS, REFRESH_ICON, TICK_ICON } from '../../../icons';
import { generateSubscriptionCode, getDateFromDateTime } from '../../../Utils/helper';

const SubscriptionItems = ({
  subscriptionItems, setSubscriptionItems
}) => {
  const handleInputChange = (value, index, field) => {
    setSubscriptionItems(prev => {
      let newBlend = structuredClone(prev);
      if (field === 'active') {
        newBlend = newBlend.map(i => ({ ...i, active: false }))
      }
      newBlend[index][field] = value;
      return newBlend;
    });
  };

  const addRow = () => {
    const newRow = { validFrom: "", expireAt: "", code: "", maxUsers: 0 };
    setSubscriptionItems([...subscriptionItems, newRow]);
  };
  const handleDeleteRow = index => {
    setSubscriptionItems(prev => prev.filter((row, i) => i !== parseInt(index)));
  };

  return (
    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex flex-1 overflow-auto'>
      <legend className='sub-heading'>Subscription Details</legend>
      <div
        className="h-[400px] overflow-auto"
      >
        <table className="table-fixed text-center w-full">
          <thead className="border-2 table-header">
            <tr className='h-2'>
              <th
                className="border-2  top-0 stick-bg w-10">
                S. no.
              </th>
              <th
                className="border-2  top-0 stick-bg"
              >
                <label>Subs.Code</label>
              </th>
              <th
                className="border-2  top-0 stick-bg"
              >
                <label>From</label>
              </th>
              <th

                className="border-2  top-0 stick-bg"
              >
                <label>To</label>
              </th>
              {/* <th

                className="border-2  top-0 stick-bg"
              >
                <label>Max. Users</label>
              </th> */}
              <th

                className="border-2  top-0 stick-bg"
              >
                <label>Active</label>
              </th>
              <th

                className="border-2 w-10  top-0 stick-bg"
              >
                <label className='text-green-700' onClick={addRow}>{PLUS}</label>
              </th>
            </tr>
          </thead>
          <tbody className="border-2">
            {(subscriptionItems).map((dataObj, index) => (
              <tr
                key={dataObj.id}
                className="border-2 table-row "
              >
                <td className='py-1'> {(index + 1)}</td>
                <td className='py-1'>
                  <div className='grid grid-cols-3 items-center justify-center'>
                    <input type="text" className='col-span-2 text-center' value={dataObj?.code} disabled />
                    <button onClick={() => handleInputChange(generateSubscriptionCode(), index, 'code')}>
                      {REFRESH_ICON}
                    </button>
                  </div>
                </td>
                <td className='py-1'>
                  <div className='grid items-center justify-center'>
                    <input type="date" onChange={(e) => handleInputChange(e.target.value, index, 'validFrom')} value={getDateFromDateTime(dataObj?.validFrom)} />
                  </div>
                </td>
                <td className='py-1'>
                  <div className='grid items-center justify-center'>
                    <input type="date" onChange={(e) => handleInputChange(e.target.value, index, 'expireAt')} value={getDateFromDateTime(dataObj?.expireAt)} />
                  </div>
                </td>
                {/* <td className='py-1'>
                  <div className='grid items-center justify-center text-right'>
                    <input type="number" min={0} className='text-right' onChange={(e) => handleInputChange(e.target.value, index, 'maxUsers')} value={dataObj?.maxUsers} />
                  </div>
                </td> */}
                <td className='py-1'>
                  <button className='grid items-center justify-center text-right h-[50px] w-full' onClick={() => handleInputChange((dataObj?.active) ? false : true, index, 'active')}>
                    {dataObj.active &&
                      <span className='text-green-700 text-xl'>{TICK_ICON}</span>
                    }
                  </button>
                </td>
                <td className='py-1'>
                  <button className='grid items-center justify-center text-right h-[50px] w-full' onClick={() => handleDeleteRow(index)}>
                    {DELETE}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default SubscriptionItems