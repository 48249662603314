import io from 'socket.io-client';
import { useEffect } from "react";
import { getCommonParams } from "../Utils/helper";
import { useGetUserByIdQuery } from '../redux/services/UsersMasterService';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const socket = io.connect(BASE_URL);

export const sendNotification = (receiverId) => {
    socket.emit(`sendNotification`, { receiverId });
}

export const sendNotificationToAdmin = () => {
    socket.emit(`sendNotificationToAdmin`);
}

export const useNotification = (callback) => {
    const { userId } = getCommonParams()
    const { data: userData } = useGetUserByIdQuery(userId)

    useEffect(() => {
        socket.on('connect', () => {
            console.log("connected")
        });

        socket.on('disconnect', () => {
            console.log("disconnect")
        });
        if (userData?.data?.isAdmin ? JSON.parse(userData?.data?.isAdmin) : false) {
            socket.on(`receiveNotificationFromAdmin`, () => {
                callback()
            })
        } else {
            socket.on(`receiveNotification/${parseInt(userId)}`, () => {
                callback()
            })
        }
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            if (userData?.data?.isAdmin ? JSON.parse(userData?.data?.isAdmin) : false) {
                socket.off(`receiveNotificationFromAdmin`);
            } else {
                socket.off(`receiveNotification/${parseInt(userId)}`)
            }
        }
    }, [callback, userData, userId])
};

export default useNotification;