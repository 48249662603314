import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetProjectQuery,
  useGetProjectByIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} from "../../../redux/ErpServices/ProjectServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { LongDropdownInput, DisabledInput, DateInput, TextInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';

import moment from "moment";
import Modal from "../../../UiComponents/Modal";
import SearchReport from "./SearchReport";

import { getCommonParams } from "../../../Utils/helper";
import SubscriptionItems from "./SubscriptionItems";

const MODEL = "Project";

export default function Project() {

  const today = new Date()
  const [readOnly, setReadOnly] = useState(false);
  const [docId, setDocId] = useState("")
  const [id, setId] = useState("");
  const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
  const [partyId, setPartyId] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [name, setName] = useState("");
  const [subscriptionItems, setSubscriptionItems] = useState([]);


  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()



  const params = {
    branchId, companyId, finYearId
  };

  const { data: supplierList } =
    useGetPartyQuery({ params: { ...params } });

  const { data: allData, isLoading, isFetching } = useGetProjectQuery({ params, searchParams: searchValue });

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProjectByIdQuery(id, { skip: !id });

  const [addData] = useAddProjectMutation();
  const [updateData] = useUpdateProjectMutation();
  const [removeData] = useDeleteProjectMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setPartyId(data?.partyId || "");
    setDate(data?.createdAt ? moment.utc(data.createdAt).format("YYYY-MM-DD") : moment.utc(new Date()).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data?.docId)
    }
    setSubscriptionItems(data?.ProjectSubscription || []);
    setName(data?.name || '')
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    partyId,
    subscriptionItems,
    name,
    branchId, id, userId,
    finYearId,
  }


  const validateData = (data) => {
    return data.partyId
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }

      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
        return
      } else {
        setId(returnData.data.id)
        toast.success(text + "Successfully");
      }
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
    getNextDocId()
  };


  const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Party"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const allSuppliers = supplierList ? supplierList.data : []

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <SearchReport
          heading={MODEL}
          tableHeaders={tableHeadings}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          tableWidth="100%"
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          openReport={() => { setFormReport(true) }}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={"flex flex-col"}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto'>
                      <legend className='sub-heading'>Project Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-5 w-full">
                          <DisabledInput name="Project no." value={docId} required={true}
                          />
                          <DateInput name="Doc Date" value={date} type={"date"} required={true} readOnly={readOnly} disabled />
                          <div className="col-span-2">
                            <LongDropdownInput name="Party" options={dropDownListObject(allSuppliers, "name", "id")} value={partyId} setValue={setPartyId} required={true} readOnly={readOnly} />
                          </div>
                          <TextInput name="Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                        </div>
                      </div>
                    </fieldset>
                    <SubscriptionItems subscriptionItems={subscriptionItems} setSubscriptionItems={setSubscriptionItems} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}