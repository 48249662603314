import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { NOTIFICATION_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const NotificationApi = createApi({
  reducerPath: "Notification",
  baseQuery,
  tagTypes: ["Notification"],
  endpoints: (builder) => ({
    getNotification: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: NOTIFICATION_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: NOTIFICATION_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Notification"],
    }),
    getNotificationById: builder.query({
      query: (id) => {
        return {
          url: `${NOTIFICATION_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Notification"],
    }),
    addNotification: builder.mutation({
      query: (payload) => ({
        url: NOTIFICATION_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Notification"],
    }),
    updateNotification: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${NOTIFICATION_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Notification"],
    }),
    deleteNotification: builder.mutation({
      query: (id) => ({
        url: `${NOTIFICATION_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const {
  useGetNotificationQuery,
  useGetNotificationByIdQuery,
  useAddNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
} = NotificationApi;

export default NotificationApi;
