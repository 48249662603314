const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const INITIAL_PAGE_NUMBER = 1;
export const DATA_PER_PAGE = 30;

export const RAIN_DOT_WEBSITE_LINK = "www.raindot.in"
export const ACC_HOLDER_NAME = "P.S TEX"
export const RAIN_DOT_DES = "Rain Dot Branded T-Shirts"

export function getImageUrlPath(fileName) {
    return `${BASE_URL + 'retreiveFile/'}${fileName}`
}

export const BG_COLOR = 'bg-[#388087]';